<template>
  <div class="topnav">
    <!-- <div class="titles" @click="goRouter('./')">
      <div :class="[true?'inner_img_box_two':'inner_img_box_one']">
        <img src="../../assets/imgs/app/topnav-button@2x.png" @click="handleClickBtn" alt />
      </div>
    </div> -->
    <div class="titleLine">
      <div class="inner">
        <img src="../../assets/imgs/app/top/icon.png" class="arcanite" @click="home" />
      </div>
      <!-- <img src="../../assets/imgs/app/topnav-button@2x.png" class="topLeft" @click="handleClickBtn" /> -->
      <div class="topLeft"  @click="handleClickBtn">
        <lottie :options="defaultOptions" :height="40" :width="40" @animCreated="handleAnimation" :speed="2"/>
      </div>
    </div>
    <transition name='fade'>
      <div class="topDetail_total" v-if="showDetail==true">
        <div class="topDetail" >
          <div class="title" v-for="i,index in detail" :key="'detail'+index" @click="goRouter(i.path,index)">
            {{i.title}}
            <div v-if="i.details">
              <div v-for="j,indexj in i.details" :key="'products'+indexj">
                <div v-if="showColor[indexj]==false" class="line" @touchstart="testdown(indexj)" @touchend="testup(j.subPath,indexj)">
                  <img :src="j.icon" class="icon" />
                  <!-- <div class="subTitle" @click="goRouter(j.subPath)">{{j.subTitle}}</div> -->
                  <div class="subTitle">{{j.subTitle}}</div>
                </div>
                <div v-else class="lineColor" @touchstart="testdown(indexj)" @touchend="testup(j.subPath,indexj)">
                  <img :src="j.icon" class="icon" />
                  <!-- <div class="subTitle" @click="goRouter(j.subPath)">{{j.subTitle}}</div> -->
                  <div class="subTitle">{{j.subTitle}}</div>
                </div>
              </div>
            </div>

          </div>

          <!-- ,safariRes:isSafari -->
          <div class="nav_bottom" :class="{iPhoneRes:isiPhone}"
          >
            <a v-if="!isID" href=" " onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');return false;">
              <div class="bottom_button">
                <span class="button_text">{{ $t("pricing.book_a_demo") }}</span>
              </div>
            </a>
            <a v-else href=" " onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap');return false;">
              <div class="bottom_button">
                <span class="button_text">{{ $t("pricing.book_a_demo") }}</span>
              </div>
            </a>
          </div>
          <div :class="{white_board:isiPhone}"></div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isID:false,
      userPhoneSystem:'',
      isiPhone:false,
      isSafari:false,
      userId: "",
      name: "Unknown User",
      email: "",
      appid:"uu6ddns6",
      defaultOptions: {
        animationData: require('@/assets/imgs/app/top/menuAni.json'),
        autoplay:false,
        loop:false,
        playSpeed:2,},
      animationPlay:true,
      showMenuFlag: "1",
      time: '',
      value: 0,
      detail: [{
        title: this.$t("topNav.home"),
        path: '/'
      }, {
        title: this.$t("topNav.products"),
        details: [{
          subTitle: this.$t("topNav.arcanite"),
          icon: require('@/assets/imgs/app/top/arcanite.png'),
          subPath: '/Arcanite',
        }, {
          subTitle: this.$t("topNav.arcanite_connect"),
          icon: require('@/assets/imgs/app/top/arcaniteConnect.png'),
          subPath: '/ArcaniteConnect',
        }, {
          subTitle: this.$t("topNav.arcanite_partners"),
          icon: require('@/assets/imgs/app/top/arcanitePartner.png'),
          subPath: '/ArcanitePartners',
        }, 
        // {
        //   subTitle: 'Equimate',
        //   icon: require('@/assets/imgs/app/top/equimate.png'),
        //   subPath: '/Equimate',
        // }, 
        {
          subTitle: this.$t("topNav.dragonVR"),
          icon: require('@/assets/imgs/app/top/dragonvr.png'),
          subPath: '/DragonVR',
        }, {
          subTitle: this.$t("topNav.project_marketplace"),
          icon: require('@/assets/imgs/app/top/marketplace.png'),
          subPath:'/Marketplace',
        }]
      }, {
        title: this.$t("topNav.features"),
        path: '/Features'
      }, {
        title: this.$t("topNav.pricing"),
        path: '/Pricing',
      }, {
        title: this.$t("topNav.blogs"),
        path: '/Blogs',
      }, {
        title: this.$t("topNav.contact_us"),
        path: '/ContactUs',
      }],
      showDetail: false,
      showColor: [false, false, false, false, false, false],
    };
    
  },
  created(){
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
    this.userPhoneSystem = localStorage.userPhone;
    if (this.userPhoneSystem=="iPhone"){
      this.isiPhone=true;
    }
    // else if(this.userPhoneSystem=="iPhoneSafari"){
    //   // this.isSafari=true;
    //   this.isiPhone=true;
    // }
    else{
      this.isiPhone=false;
      this.isSafari=false;
    }
  },
  mounted(){
    // if(this.isID){

    // }
    // const convertStyle = () => {
    //     document.body.style.setProperty('height', `${window.innerHeight}px`);
    // }

    // window.addEventListener("resize", convertStyle);
    // window.addEventListener("DOMContentLoaded", convertStyle);
    // window.Intercom('boot',{
    //       app_id: this.appid,
    //       user_id: this.userId,
    //       name: this.name,
    //       email: this.email,
    //     });


    //     /**
    //  * 44px is the height of the Safari overflow bar
    //  */
    // const SAFARI_MOBILE_BOTTOM_MENU_HEIGHT = 44;

    // /**
    //  * Import from a library such as lodash or implement your own. Scroll events
    //  * should always be throttled or debounced.
    //  */
    // const throttle = () => {};

    // /**
    //  * When bottom > window.innerHeight, we know for a fact that `element` is
    //  * outside the window bounds. Normally, `position: fixed` keeps the element
    //  * inside the window bounds.
    //  *
    //  * @param {Element} element
    //  * @param {Number} offsetBottom - in pixels
    //  */
    // const reactToSafariOverflowBar = (element, offsetBottom) => {
    //   const {bottom} = element.getBoundingClientRect();
    //   if (Math.floor(bottom) > window.innerHeight) {
    //     element.style.bottom = offsetBottom + SAFARI_MOBILE_BOTTOM_MENU_HEIGHT;
    //   } else {
    //     element.style.bottom = offsetBottom;
    //   }
    // };

    // const offsetBottom = 20;
    // const stuckToBottom = document.querySelector('.is-stuckToBottom');

    // /**
    //  * Handle scroll events. This listener should be removed if the component
    //  * is removed, but this serves its demo purposes.
    //  */
    // window.addEventListener('scroll', throttle(() => reactToSafariOverflowBar(stuckToBottom, offsetBottom), 200));

  },
  watch: {
    // email(email) {
    //     Intercom("update",{ email:this.email })
    // },
    // name(name) {
    //     Intercom("update",{ name:this.name })
    // },
    // userId(user_id) {
    //     Intercom("update",{ user_id:this.userId })
    // },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
    },
    playStart(){
      this.anim.playSegments([0,70],true);
      this.animationPlay=!this.animationPlay
      this.anim.setSpeed(2);
    },
    playEnd(){
      this.anim.setSpeed(2);
      this.anim.playSegments([70,140],true);
      this.animationPlay=!this.animationPlay
    },
    // goTitle(path,index){
    //   if(index!=5){
    //     this.goRouter(path)
    //   }
    //   else{
    //     this.handleClickContactUS;
    //   }
    // },
    // handleClickContactUS() {
    //   window.Intercom("show");
    // },
    get() {
      this.value++;
    },
    goRouter(path) {
      if (path != '/ContactUs') {
        this.$router.push(path);
      } else {
        if(this.isID){
          this.callWhatsApp();
        }else if(!this.isID){
          window.HubSpotConversations.widget.open(); 
        }
      }
    },
    callWhatsApp(){
      let data = "https://api.whatsapp.com/send?phone=6281319386791"
      window.open(data,'self')
    },
    handleClickBtn() {
      this.showDetail = !this.showDetail;
      // this.playCut();
      if(this.animationPlay==true){
        this.playStart()
      }
      else{
        this.playEnd()
      }
      // this.$router.push('./');
    },
    home() {
      this.handleClickBtn();
      this.goRouter('/')
    },
    testdown(i) {
      this.timer = setInterval(this.get, 1000);
      this.showColor.splice(i, 1, true);
    },
    testup(path, i) {
      clearInterval(this.timer);
      this.showColor.splice(i, 1, false);
      if (this.value <= 0) {
        this.goRouter(path)
      }
      this.value = 0;
    }

  },
  props: ["isARClogo", "isResourceList"]
};
</script>
<style lang="scss" scoped>
.topnav {
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 1);
  .titleLine {
    z-index: 99;
    background-color: #fff;
    height: 70px;
    // border-bottom: 1px solid black;
    width: 100vw;
    overflow: hidden;
    text-align: center;
    // display: inline-block;
    display: flex;
    align-items: center;/*垂直居中*/
    // height:300px;
    .inner{
      text-align: center;
      display: inline-block;
      width: 100vw;
    }
    .arcanite {
      // padding-top: 10px;
      width: 124.1px;
      // height: 50px;
      margin: auto;
    }
    .topLeft {
      position: absolute;
      left: 21.8px;
      // top: 25px;
      // width: 27.5px;
      // height: 17.5px;
    }
  }
}

.topDetail {
  z-index: 3;
  position: relative;
  top: 70px;
  width: 100vw;
  // 调整contact us的高度
  max-height: calc(60vh);
  padding-bottom: calc(30vh);
  // margin-bottom: 100vh;
  overflow-y: auto;
  background: #fff;
  position: fixed;
  // padding-bottom: 20px;
  border-radius: 0px 0px 5px 5px;
  // display: block;
  .title {
    font-family: Poppins-Bold;
    font-size: 14px;
    // font-weight: bold;
    font-stretch: normal;
    min-height: 39px;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
    padding: 10px 0 10px 26px;
    .line {
      display: flex;
      margin-top: 10px;
      // background: #eef7ff;
      position: relative;
      left: -26px;
      width: 100vw;
    }
    .lineColor {
      display: flex;
      margin-top: 10px;
      background: #eef7ff;
      position: relative;
      left: -26px;
      width: 100vw;
    }
    .icon {
      width: 30px;
      height: 30px;
      margin: 4px 10px 4px 26px;
    }
    .subTitle {
      margin: 9px 0 9px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #062440;
    }
  }
}
@keyframes slidein {
  from {
    margin-top: -80%;
    width: 300%;
  }

  to {
    margin-top: 0;
    width: 100%;
  }
}

@keyframes slideout {
  from {
    margin-top: 0px;
    width: 300%;
  }

  to {
    margin-top: -550px;
    width: 100%;
  }
}

.fade-enter-active {
  animation:slidein 0.3s
}
.fade-leave-active /* .fade-leave-active below version 2.1.8 */ {
  animation: slideout 0.3s;
}

.nav_bottom{
  position: fixed;
  // top: calc(80vh + 2 * constant(safe-area-inset-bottom));    /*兼容 IOS<11.2*/ 非常适合
  top: calc(100vh - 75px);
  // bottom: 0;
  display: flex;
  // flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px -2px 6px rgba(164, 164, 164, 0.5);
  border-radius: 0px 0px 5px 5px;
  width: 100%;
  height: 74px;
  z-index: 3;
  // 
  // padding-top: 16px;
  padding-bottom: 20px;



  .bottom_button{
    width: 90vw;
    height: 42px;
    background: #1890FF;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .button_text{
      color: #FFFFFF;
      font-family: 'Poppins-bold';
      font-style: normal;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }
  }

}

.iPhoneRes{
  top: calc(100vh - 190px + 3 * constant(safe-area-inset-bottom));    /*兼容 IOS<11.2*/
  top: calc(100vh - 190px + 3 * env(safe-area-inset-bottom));    /*兼容 IOS>11.2*/
  padding-bottom: 0px;
}

// .safariRes{
//   top: calc(100vh - 190px + 3 * constant(safe-area-inset-bottom));    /*兼容 IOS<11.2*/
//   top: calc(100vh - 190px + 3 * env(safe-area-inset-bottom));    /*兼容 IOS>11.2*/
//   // top: calc(100vh - 175px);
//   // margin-bottom: 40px;

// }

// .is-stuckToBottom {
//   position: fixed;
//   bottom: 20px; // A sensible offset from the edge of the viewport
// }

.topDetail_total{
  // display: flex;
  // flex-direction: column;
  // align-content: flex-end;
}

.white_board{
  content: "";
  position: fixed;
  background: #FFFFFF;
  // background: green;
  width: 100%;
  height: 275px;
  top: calc(100vh - 150px);
  z-index: 2;
}


</style>